export const getParkingLotsData = () => {
  return [
    {
      //   sectionImage: "Overview/modelo-cantina.png",
      sectionTitle: "Parking Lots",
      paragraph:
        "With a one-of-a-kind view overlooking the Las Vegas Strip, a world-class stadium backdrop and ample outdoor and indoor spaces Allegiant offers an unmatched, immersive test-driving or tail gate experience that will leave a lasting impression on attendees.",
      //   area: "20,600",
      //   reception: "500",
      // banquet: '300'
    },
  ];
};

export const getCarouselImages = () => {
  return [
    "ParkingLots/photos/parking-lots-01.jpg",
    "ParkingLots/photos/parking-lots-02.jpg",
    "ParkingLots/photos/parking-lots-03.jpg",
    "ParkingLots/photos/parking-lots-04.jpg",
    "ParkingLots/photos/parking-lots-05.jpg",
  ];
};

export const getFieldViewImages = () => {
  return [
    "MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Black.jpg",
    "MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Down.jpg",
    "MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Front.jpg",
    "MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Left.jpg",
    "MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Right.jpg",
    "MondeloCantinaClub/FieldView/VR_Section_C112_32_13_Up.jpg",
  ];
};

export const getLayoutImages = () => {
  return ["ParkingLots/layout/parking-lots-layout.jpg"];
};

export const getEventPhotosImages = () => {
  return [
    "ParkingLots/event/parking-lots-06.jpg",
    "ParkingLots/event/parking-lots-07.jpg",
    "ParkingLots/event/parking-lots-08.jpg",
    "ParkingLots/event/parking-lots-09.jpg",
    "ParkingLots/event/parking-lots-10.jpg",
    "ParkingLots/event/parking-lots-11.jpg",
    "ParkingLots/event/parking-lots-12.jpg",
  ];
};

<template>
  <section id="ParkingLotsPhotos">
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <h1>
      Gallery
      <div class="line"></div>
    </h1>
    <EventSpacesCarousel :carouselImages="carouselImages" />
  </section>
</template>
  
  <script>
import EventSpacesCarousel from "@/components/global/EventSpacesCarousel";
import { getCarouselImages } from "@/data/sectionData/eventSpaces/ParkingLots";

export default {
  name: "ParkingLotsPhotos",
  components: {
    EventSpacesCarousel,
  },
  data() {
    return {
      carouselImages: getCarouselImages(),
    };
  },
};
</script>
  
  <style lang="scss" scoped>
#ParkingLotsPhotos {
  position: relative;
  h1 {
    font-family: "ArtegraSans-Bold";
    font-size: 26px;
    color: #ffffff;
    letter-spacing: 1.62px;
    line-height: 25px;
    width: fit-content;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media (min-width: $lg) {
      display: none;
    }
    @media (max-width: 993px) {
      margin-left: 100px;
    }
    @media (max-width: $md) {
      margin-left: 50px;
    }
    @media (max-width: $sm) {
      margin-left: 20px;
    }
    .line {
      @media (min-width: $lg) {
        display: none;
      }
      height: 2px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0 0 9px 2px #ffffff;
      margin-top: 20px;
    }
  }
  .dividerOne {
    width: 100%;
    height: 2px;
    background: #1a1a1a;
    margin-top: 40px;
    @media (min-width: $lg) {
      display: none;
    }
  }
  .dividerTwo {
    width: 100%;
    height: 2px;
    background: #2c2c2c;
    margin-bottom: 40px;
    @media (min-width: $lg) {
      display: none;
    }
  }
}
</style>